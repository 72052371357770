export default {
    // msal: {
    //     scopes: process.env.AZUREAD_SCOPES || "User.ReadBasic.All, openid",
    //     authority: process.env.AZUREAD_AUTHORITY || 'https://login.microsoftonline.com',
    //     clientId: process.env.AZUREAD_CLIENTID || 'e3f81a39-3304-43e0-a648-29632302edeb',
    //     redirectUri: process.env.REDIRECT_URI || 'https://kind-coast-0cec5e810.azurestaticapps.net/',
    //     tenantId: process.env.AZUREAD_TENANTID || '36da45f1-dd2c-4d1f-af13-5abe46b99921',
    //     domain: process.env.AZUREAD_DOMAIN,
    //     graphUri: process.env.GRAPH_URI,
    //     cacheLocation: process.env.CACHE_LOCATION || 'localstorage', 
    // }

    msal: {
        scopes: process.env.AZUREAD_SCOPES,
        authority: process.env.AZUREAD_AUTHORITY,
        clientId: process.env.AZUREAD_CLIENTID,
        redirectUri: process.env.REDIRECT_URI,
        tenantId: process.env.AZUREAD_TENANTID,
        domain: process.env.AZUREAD_DOMAIN,
        graphUri: process.env.GRAPH_URI,
        cacheLocation: process.env.CACHE_LOCATION, 
    }
}
